import request from '@/utils/axios'

/** 获取海报列表 */
export function getList(params) {
  return request({
    url: '/poster',
    method: 'get',
    params
  })
}

/** 编辑海报 */
export function setCard(data) {
  return request({
    url: '/poster',
    method: 'put',
    data
  })
}
