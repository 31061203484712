<template>
  <div>
    <el-row style="margin-top:200px;">
      <el-col v-for="(item, index) in cardList" :key="index" :span="8">
        <div class="wxCard">
          <div class="title">{{ type[item.type - 1] }}</div>
          <img class="code-img" :src="item.imgUrl" alt="">
          <div v-if="item.type === 1" class="message">卡片尺寸250*200，支持jpg</div>
          <div v-if="item.type === 2" class="message">海报尺寸750*1000，支持jpg</div>
          <div v-if="item.type === 3" class="message">海报尺寸690*200，支持jpg</div>
          <el-button type="primary" class="btn" @click="edit(item.id, item.type)">编辑</el-button>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      title="编辑海报"
      :visible.sync="show"
      width="600px"
    >
      <el-form v-if="show" label-width="auto">
        <el-form-item label="海报">
          <el-upload
            action="#"
            :http-request="uploadImage"
            :show-file-list="false"
            :limit="1"
          >
            <el-image v-if="imgUrl" :src="imgUrl" />
            <i v-else class="el-icon-plus uploader-icon" />
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <el-row type="flex" justify="end">
        <el-button @click="show = false">取消</el-button>
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { getList, setCard } from '@/api/poster'
import { ApiUpload } from '@/api/utils'
export default {
  data() {
    return {
      cardList: [],
      type: ['微信小卡片', '分享海报', '高能团队海报'],
      show: false,
      cardType: '',
      cardId: '',
      imgUrl: ''
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      getList({ page: 1, pageSize: 999 }).then(res => {
        this.cardList = res.data.list
      })
    },
    edit(id, type) {
      this.cardId = id
      this.show = true
      this.cardType = type
    },
    upload(file) {
      return ApiUpload(file)
    },
    uploadImage({ file }) {
      this.upload(file).then(res => {
        this.imgUrl = res.data.fileUrl
      })
    },
    onSubmit() {
      setCard({ id: this.cardId, imgUrl: this.imgUrl, type: this.cardType }).then(() => {
        this.$message({
          message: '修改成功',
          type: 'success'
        })
        this.show = false
        this.imgUrl = ''
        this.getList()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .wxCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .code-img {
      display: block;
      width: 280px;
      height: 150px;
      border: 10px solid #cccccc;
      margin-bottom: 10px;
    }
    .message {
      margin-bottom: 20px;
    }
    .btn {
      width: 100px;
      height: 40px;
    }
  }
</style>
